<template>
    <div class="main-content">
        <div class="goods-box" v-if="[1,3].includes(module)">
            <div class="head-content">
                <div class="goods-head">
                    <div class="head-left">
                        <span class="head-left-title">关键词</span>
                        <el-select v-model="keyword" placeholder="关键词名称" style="width: 200px;" ref="keyword">
                            <el-option
                                    v-for="item in keywordList"
                                    :key="item.id"
                                    :label="item.name"
                                    :value="item.id">
                            </el-option>
                        </el-select>
                    </div>
                </div>
                <el-button type="primary" @click="addGoods">添加商品</el-button>
            </div>
            <el-table class="content-table customTable" :data="goodsList"
                      style="width: 100%; margin-top: 20px;" size="medium"
                      :header-cell-style="{fontWeight: 'normal', height: '60px', color: '#666666', background: '#F6F6F6',fontSize: '16px'}"
                      :cell-style="{fontSize: '16px',color: '#333333',height: '70px'}">
                <el-table-column prop="title" align="left" label="商品标题">
                    <template slot-scope="scope">
                        <el-select v-model="scope.row.good_id" placeholder="商品标题" style="width: 320px;"
                                   :ref="`goods_select_${scope.$index}`" @change="selectGoodsChange">
                            <el-option
                                    v-show="isSelectGoods(item.id)"
                                    v-for="item in goodsInfoList"
                                    :key="item.id"
                                    :label="item.good_title"
                                    :value="item.id">
                            </el-option>
                            <el-option :value="0" label="暂无数据" v-if="goodsChoosableList.length === goodsList.length"
                                       :disabled="true"></el-option>
                        </el-select>
                    </template>
                </el-table-column>
                <el-table-column prop="price" align="center" label="价格" width="150">
                    <template slot-scope="scope">
                        <el-input-number :min="0.1"
                                         :max="99999999"
                                         :controls="false"
                                         :precision="2" v-model="scope.row.price"
                                         :ref="`goods_price_${scope.$index}`"></el-input-number>
                    </template>
                </el-table-column>
                <el-table-column prop="sales" align="center" label="销量" width="150">
                    <template slot-scope="scope">
                        <el-input-number :min="0"
                                         :max="99999999"
                                         :controls="false"
                                         :precision="0" v-model="scope.row.sales"
                                         :ref="`goods_sales_${scope.$index}`"></el-input-number>
                    </template>
                </el-table-column>
                <el-table-column prop="conversion" align="center" label="转化率（%）" width="150">
                    <template slot-scope="scope">
                        <el-input-number :min="0"
                                         :max="100"
                                         :controls="false"
                                         @input.native="changeInput($event)" v-model="scope.row.conversion"
                                         :ref="`goods_conversion_${scope.$index}`"></el-input-number>
                    </template>
                </el-table-column>
                <el-table-column prop="click_rate" align="center" label="点击率（%）" width="150">
                    <template slot-scope="scope">
                        <el-input-number :min="0"
                                         :max="100"
                                         :controls="false"
                                         @input.native="changeInput($event)" v-model="scope.row.click_rate"
                                         :ref="`goods_click_${scope.$index}`"></el-input-number>
                    </template>
                </el-table-column>
                <el-table-column prop="quality_score" align="center" label="质量分" width="150">
                    <template slot-scope="scope">
                        <el-input-number :min="0"
                                         :max="99999"
                                         :controls="false"
                                         :precision="0" v-model="scope.row.quality_score"
                                         :ref="`goods_quality_score_${scope.$index}`"></el-input-number>
                    </template>
                </el-table-column>
                <el-table-column prop="wave_range" align="center" label="波动范围">
                    <template slot-scope="scope">
                        <div class="wave-box">
                            <el-input-number :min="-99"
                                             :max="100"
                                             :controls="false"
                                             :precision="0" v-model="scope.row.wave_range_start"
                                             :ref="`goods_range_start_${scope.$index}`"></el-input-number>
                            <span>%</span>
                            <div class="divide-line"></div>
                            <el-input-number :min="-99"
                                             :max="100"
                                             :controls="false"
                                             :precision="0" v-model="scope.row.wave_range_end"
                                             :ref="`goods_range_end_${scope.$index}`"></el-input-number>
                            <span>%</span>
                        </div>
                    </template>
                </el-table-column>
                <el-table-column align="center" label="操作" width="150">
                    <template slot-scope="scope">
                        <el-link type="danger" :underline="false" @click="delGoods(scope.$index)">删除
                        </el-link>
                    </template>
                </el-table-column>
            </el-table>
        </div>
        <div class="setting-box" v-if="!isSkipSetting">
            <div class="content-title">时段设置</div>
            <div class="item-content">
                <div class="item-title"></div>
                <div class="item-content-title">潜在客户数</div>
                <div class="item-content-title">点击率</div>
                <div class="item-content-title">转化率</div>
            </div>
            <div class="item-content main" v-for="(item,index) in timeSetting" :key="index">
                <div class="even-content">
                    <div class="item-title">{{item.title}}</div>
                    <el-input-number class="item-input max" :ref="`time_client_num_${index}`" v-model="item.client_num"
                                     :min="0"
                                     :max="99999"
                                     :controls="false"
                                     :precision="0"></el-input-number>
                    <el-input-number class="item-input medium" :ref="`time_click_rate_${index}`"
                                     v-model="item.click_rate"
                                     :min="0"
                                     :max="100"
                                     :controls="false"
                                     @input.native="changeInput($event)"></el-input-number>
                    <span class="input-text">%</span>
                    <el-input-number class="item-input medium" :ref="`time_transform_rate_${index}`"
                                     v-model="item.transform_rate"
                                     :min="0"
                                     :max="100"
                                     :controls="false"
                                     @input.native="changeInput($event)"></el-input-number>
                    <span class="input-text">%</span>
                </div>
                <div class="odd-content">
                    <div class="item-title">波动范围</div>
                    <div class="odd-item">
                        <el-input-number class="item-input mini" :ref="`time_client_num_start_${index}`"
                                         v-model="item.client_num_start"
                                         :min="-99"
                                         :max="100"
                                         :controls="false"
                                         :precision="0"></el-input-number>
                        <span class="input-text">%</span>
                        <div class="divide"></div>
                        <el-input-number class="item-input mini" :ref="`time_client_num_end_${index}`"
                                         v-model="item.client_num_end"
                                         :min="-99"
                                         :max="100"
                                         :controls="false"
                                         :precision="0"></el-input-number>
                        <span class="input-text">%</span>
                    </div>
                    <div class="odd-item">
                        <el-input-number class="item-input mini" :ref="`time_click_rate_start_${index}`"
                                         v-model="item.click_rate_start"
                                         :min="-99"
                                         :max="100"
                                         :controls="false"
                                         :precision="0"></el-input-number>
                        <span class="input-text">%</span>
                        <div class="divide"></div>
                        <el-input-number class="item-input mini" :ref="`time_click_rate_end_${index}`"
                                         v-model="item.click_rate_end"
                                         :min="-99"
                                         :max="100"
                                         :controls="false"
                                         :precision="0"></el-input-number>
                        <span class="input-text">%</span>
                    </div>
                    <div class="odd-item">
                        <el-input-number class="item-input mini" :ref="`time_transform_rate_start_${index}`"
                                         v-model="item.transform_rate_start"
                                         :min="-99"
                                         :max="100"
                                         :controls="false"
                                         :precision="0"></el-input-number>
                        <span class="input-text">%</span>
                        <div class="divide"></div>
                        <el-input-number class="item-input mini" :ref="`time_transform_rate_end_${index}`"
                                         v-model="item.transform_rate_end"
                                         :min="-99"
                                         :max="100"
                                         :controls="false"
                                         :precision="0"></el-input-number>
                        <span class="input-text">%</span>
                    </div>
                </div>
            </div>
        </div>
        <div class="setting-box" v-if="!isSkipSetting">
            <div class="content-title">地区设置</div>
            <div class="item-content">
                <div class="item-title"></div>
                <div class="item-content-title">潜在客户数</div>
                <div class="item-content-title">点击率</div>
                <div class="item-content-title">转化率</div>
            </div>
            <div class="item-content" v-for="(item,index) in areaSetting" :key="index">
                <div class="even-content">
                    <div class="item-title">{{item.title}}</div>
                    <el-input-number class="item-input max" :ref="`area_client_num_${index}`" v-model="item.client_num"
                                     :min="0"
                                     :max="99999"
                                     :controls="false"
                                     :precision="0"></el-input-number>
                    <el-input-number class="item-input medium" :ref="`area_click_rate_${index}`"
                                     v-model="item.click_rate"
                                     :min="0"
                                     :max="100"
                                     :controls="false"
                                     @input.native="changeInput($event)"></el-input-number>
                    <span class="input-text">%</span>
                    <el-input-number class="item-input medium" :ref="`area_transform_rate_${index}`"
                                     v-model="item.transform_rate"
                                     :min="0"
                                     :max="100"
                                     :controls="false"
                                     @input.native="changeInput($event)"></el-input-number>
                    <span class="input-text">%</span>
                </div>
                <div class="odd-content">
                    <div class="item-title">波动范围</div>
                    <div class="odd-item">
                        <el-input-number class="item-input mini" :ref="`area_client_num_start_${index}`"
                                         v-model="item.client_num_start"
                                         :min="-99"
                                         :max="100"
                                         :controls="false"
                                         :precision="0"></el-input-number>
                        <span class="input-text">%</span>
                        <div class="divide"></div>
                        <el-input-number class="item-input mini" :ref="`area_client_num_end_${index}`"
                                         v-model="item.client_num_end"
                                         :min="-99"
                                         :max="100"
                                         :controls="false"
                                         :precision="0"></el-input-number>
                        <span class="input-text">%</span>
                    </div>
                    <div class="odd-item">
                        <el-input-number class="item-input mini" :ref="`area_click_rate_start_${index}`"
                                         v-model="item.click_rate_start"
                                         :min="-99"
                                         :max="100"
                                         :controls="false"
                                         :precision="0"></el-input-number>
                        <span class="input-text">%</span>
                        <div class="divide"></div>
                        <el-input-number class="item-input mini" :ref="`area_click_rate_end_${index}`"
                                         v-model="item.click_rate_end"
                                         :min="-99"
                                         :max="100"
                                         :controls="false"
                                         :precision="0"></el-input-number>
                        <span class="input-text">%</span>
                    </div>
                    <div class="odd-item">
                        <el-input-number class="item-input mini" :ref="`area_transform_rate_start_${index}`"
                                         v-model="item.transform_rate_start"
                                         :min="-99"
                                         :max="100"
                                         :controls="false"
                                         :precision="0"></el-input-number>
                        <span class="input-text">%</span>
                        <div class="divide"></div>
                        <el-input-number class="item-input mini" :ref="`area_transform_rate_end_${index}`"
                                         v-model="item.transform_rate_end"
                                         :min="-99"
                                         :max="100"
                                         :controls="false"
                                         :precision="0"></el-input-number>
                        <span class="input-text">%</span>
                    </div>
                </div>
            </div>
        </div>
        <div class="setting-box" v-if="isSkipSetting">
            <div class="content-title">时段设置</div>
            <div class="vertical-content">
                <div class="vertical-item" v-for="(item,index) in timeArr" :key="index">
                    <div class="left-box" v-if="index % 6 === 0">
                        <div class="inner-item">潜在客户数</div>
                        <div class="inner-item">点击率</div>
                        <div class="inner-item">转化率</div>
                    </div>
                    <div class="right-box">
                        <div class="item-title">{{item.title}}</div>
                        <el-input-number class="item-input max" :ref="`time_skip_client_num_${index}`"
                                         v-model="item.client_num"
                                         :min="0"
                                         :max="99999"
                                         :controls="false"
                                         :precision="0"></el-input-number>
                        <div class="inner-item">
                            <el-input-number class="item-input medium" :ref="`time_skip_click_rate_${index}`"
                                             v-model="item.click_rate"
                                             :min="0"
                                             :max="100"
                                             :controls="false"
                                            @input.native="changeInput($event)"></el-input-number>
                            <span class="input-text">%</span>
                        </div>
                        <div class="inner-item">
                            <el-input-number class="item-input medium" :ref="`time_skip_transform_rate_${index}`"
                                             v-model="item.transform_rate"
                                             :min="0"
                                             :max="100"
                                             :controls="false"
                                             @input.native="changeInput($event)"></el-input-number>
                            <span class="input-text">%</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="setting-box" v-if="isSkipSetting">
            <div class="content-title">地区设置</div>
            <div class="vertical-content">
                <div class="vertical-item" v-for="(item,index) in areaArr" :key="index">
                    <div class="left-box" v-if="index % 6 === 0">
                        <div class="inner-item">潜在客户数</div>
                        <div class="inner-item">点击率</div>
                        <div class="inner-item">转化率</div>
                    </div>
                    <div class="right-box">
                        <div class="item-title">{{item.title}}</div>
                        <el-input-number class="item-input max" :ref="`area_skip_client_num_${index}`"
                                         v-model="item.client_num"
                                         :min="0"
                                         :max="99999"
                                         :controls="false"
                                         :precision="0"></el-input-number>
                        <div class="inner-item">
                            <el-input-number class="item-input medium" :ref="`area_skip_click_rate_${index}`"
                                             v-model="item.click_rate"
                                             :min="0"
                                             :max="100"
                                             :controls="false"
                                             @input.native="changeInput($event)"></el-input-number>
                            <span class="input-text">%</span>
                        </div>
                        <div class="inner-item">
                            <el-input-number class="item-input medium" :ref="`area_skip_transform_rate_${index}`"
                                             v-model="item.transform_rate"
                                             :min="0"
                                             :max="100"
                                             :controls="false"
                                             @input.native="changeInput($event)"></el-input-number>
                            <span class="input-text">%</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="setting-box">
            <div class="content-title">人群定向</div>
            <div class="item-content">
                <div class="item-title"></div>
                <div class="item-content-title">潜在客户数</div>
                <div class="item-content-title">点击率</div>
                <div class="item-content-title">转化率</div>
            </div>
            <div class="item-content" v-for="(item,index) in peopleSetting" :key="index">
                <div class="even-content">
                    <div class="item-title">{{item.title}}</div>
                    <el-input-number class="item-input medium" :ref="`people_client_num_${index}`"
                                     v-model="item.client_num"
                                     :min="0"
                                     :max="99999"
                                     :controls="false"
                                     :precision="0"></el-input-number>
                    <span class="input-text">%</span>
                    <el-input-number class="item-input medium" :ref="`people_click_rate_${index}`"
                                     v-model="item.click_rate"
                                     :min="0"
                                     :max="100"
                                     :controls="false"
                                     @input.native="changeInput($event)"></el-input-number>
                    <span class="input-text">%</span>
                    <el-input-number class="item-input medium" :ref="`people_transform_rate_${index}`"
                                     v-model="item.transform_rate"
                                     :min="0"
                                     :max="100"
                                     :controls="false"
                                     @input.native="changeInput($event)"></el-input-number>
                    <span class="input-text">%</span>
                </div>
            </div>
        </div>
        <div class="setting-box" v-if="[2,4].includes(module)">
            <div class="content-title">资源位</div>
            <div class="item-content">
                <div class="item-title"></div>
                <div class="item-content-title">潜在客户数</div>
                <div class="item-content-title">点击率</div>
                <div class="item-content-title">转化率</div>
            </div>
            <div class="item-content" v-for="(item,index) in resourceSetting" :key="index">
                <div class="even-content">
                    <div class="item-title">{{item.title}}</div>
                    <el-input-number class="item-input medium" :ref="`resource_client_num_${index}`"
                                     v-model="item.client_num"
                                     :min="0"
                                     :max="99999"
                                     :controls="false"
                                     :precision="0"></el-input-number>
                    <span class="input-text">%</span>
                    <el-input-number class="item-input medium" :ref="`resource_click_rate_${index}`"
                                     v-model="item.click_rate"
                                     :min="0"
                                     :max="100"
                                     :controls="false"
                                     @input.native="changeInput($event)"></el-input-number>
                    <span class="input-text">%</span>
                    <el-input-number class="item-input medium" :ref="`resource_transform_rate_${index}`"
                                     v-model="item.transform_rate"
                                     :min="0"
                                     :max="100"
                                     :controls="false"
                                     @input.native="changeInput($event)"></el-input-number>
                    <span class="input-text">%</span>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import {areaList} from '@/utils/area';
    import {
        operationPromote_gData,
        operationPromote_getKeyWorkList,
        adminGoodListGood,
        operationPromote_getPosition
    } from '@/utils/apis'
    import {mapMutations, mapState} from "vuex";

    export default {
        name: "AddOperationPromoteData",
        props: ['editData', 'module', 'isSkipSetting', 'category_id'],
        data() {
            return {
                keyword: '',
                keywordList: [],
                goodsList: [],
                goodsInfoList: [],
                goodsChoosableList: [],
                areaArr: [],
                timeArr: [],
                timeSetting: [
                    {
                        title: '0~6',
                        client_num: void 0,
                        click_rate: void 0,
                        transform_rate: void 0,
                        client_num_start: void 0,
                        client_num_end: void 0,
                        click_rate_start: void 0,
                        click_rate_end: void 0,
                        transform_rate_start: void 0,
                        transform_rate_end: void 0,
                    },
                    {
                        title: '6~12',
                        client_num: void 0,
                        click_rate: void 0,
                        transform_rate: void 0,
                        client_num_start: void 0,
                        client_num_end: void 0,
                        click_rate_start: void 0,
                        click_rate_end: void 0,
                        transform_rate_start: void 0,
                        transform_rate_end: void 0,
                    },
                    {
                        title: '12~18',
                        client_num: void 0,
                        click_rate: void 0,
                        transform_rate: void 0,
                        client_num_start: void 0,
                        client_num_end: void 0,
                        click_rate_start: void 0,
                        click_rate_end: void 0,
                        transform_rate_start: void 0,
                        transform_rate_end: void 0,
                    },
                    {
                        title: '18~24',
                        client_num: void 0,
                        click_rate: void 0,
                        transform_rate: void 0,
                        client_num_start: void 0,
                        client_num_end: void 0,
                        click_rate_start: void 0,
                        click_rate_end: void 0,
                        transform_rate_start: void 0,
                        transform_rate_end: void 0,
                    },
                ],
                areaSetting: [],
                peopleSetting: [
                    {
                        title: '核心人群',
                        client_num: void 0,
                        click_rate: void 0,
                        transform_rate: void 0,
                    },
                    {
                        title: '认知人群',
                        client_num: void 0,
                        click_rate: void 0,
                        transform_rate: void 0,
                    },
                    {
                        title: '意向人群',
                        client_num: void 0,
                        click_rate: void 0,
                        transform_rate: void 0,
                    },
                    {
                        title: '竞品人群',
                        client_num: void 0,
                        click_rate: void 0,
                        transform_rate: void 0,
                    },
                    {
                        title: '店铺人群',
                        client_num: void 0,
                        click_rate: void 0,
                        transform_rate: void 0,
                    },
                    {
                        title: '类目相关人群',
                        client_num: void 0,
                        click_rate: void 0,
                        transform_rate: void 0,
                    },
                ],
                resourceSetting: [
                    {
                        title: '无线焦点图',
                        client_num: void 0,
                        click_rate: void 0,
                        transform_rate: void 0,
                    },
                    {
                        title: '分类banner',
                        client_num: void 0,
                        click_rate: void 0,
                        transform_rate: void 0,
                    },
                    {
                        title: '订单详情通栏',
                        client_num: void 0,
                        click_rate: void 0,
                        transform_rate: void 0,
                    },
                    {
                        title: 'PC首页右侧小图',
                        client_num: void 0,
                        click_rate: void 0,
                        transform_rate: void 0,
                    },
                    {
                        title: 'PC首页焦点图',
                        client_num: void 0,
                        click_rate: void 0,
                        transform_rate: void 0,
                    },
                ],
                positionList: [
                    {
                        code: 1001,
                        name: "华北地区"
                    },
                    {
                        code: 1002,
                        name: "华中地区"
                    },
                    {
                        code: 1003,
                        name: "华东地区"
                    },
                    {
                        code: 1004,
                        name: "华南地区"
                    },
                    {
                        code: 1005,
                        name: "东北地区"
                    },
                    {
                        code: 1006,
                        name: "西北地区"
                    },
                    {
                        code: 1007,
                        name: "西南地区"
                    }
                ],
                globalList: [
                    { code: 2001, name: "东亚地区"},
                    { code: 2002, name: "东南亚"},
                    { code: 2003, name: "南亚"},
                    { code: 2004, name: "中东"},
                    { code: 2005, name: "北欧"},
                    { code: 2006, name: "东欧"},
                    { code: 2007, name: "中欧"},
                    { code: 2008, name: "西欧"},
                    { code: 2009, name: "南欧"},
                    { code: 2010, name: "美国"},
                    { code: 2011, name: "加拿大"},
                    { code: 2012, name: "拉丁美洲"},
                    { code: 2013, name: "北非"},
                    { code: 2014, name: "东非"},
                    { code: 2015, name: "中非"},
                    { code: 2016, name: "西非"},
                    { code: 2017, name: "南非"},
                ],
                peopleList: [
                    {
                        type: 0,
                        name: '核心人群'
                    },
                    {
                        type: 1,
                        name: '认知人群'
                    },
                    {
                        type: 2,
                        name: '意向人群'
                    },
                    {
                        type: 3,
                        name: '竞品人群'
                    },
                    {
                        type: 4,
                        name: '店铺人群'
                    },
                    {
                        type: 5,
                        name: '类目相关人群'
                    },
                ],
                resourceList: [
                    {
                        type: 0,
                        name: '无线焦点图'
                    },
                    {
                        type: 1,
                        name: '分类banner'
                    },
                    {
                        type: 2,
                        name: '订单详情通栏'
                    },
                    {
                        type: 3,
                        name: 'pc首页右侧小图'
                    },
                    {
                        type: 4,
                        name: 'Pc首页焦点图'
                    },
                ]
            }
        },
        created() {
            for (let i in areaList) {
                let ob = {
                    title: areaList[i].name,
                    client_num: void 0,
                    click_rate: void 0,
                    transform_rate: void 0,
                }
                this.areaArr.push(ob)
            }
            this.areaArr.splice(this.areaArr.length - 3, 3)
            for (let i = 0; i < 24; i++) {
                let obj = {
                    title: i + '~' + (i + 1),
                    client_num: void 0,
                    click_rate: void 0,
                    transform_rate: void 0,
                }
                this.timeArr.push(obj)
            }
            if ([1,3].includes(this.module)) {
                this.getKeyWords();
                this.getGoodsList();
            }
            // this.timeSetting = JSON.parse(localStorage.getItem('timeSetting'))
            // this.areaSetting = JSON.parse(localStorage.getItem('areaSetting'))
            // this.peopleSetting = JSON.parse(localStorage.getItem('peopleSetting'))
            // this.resourceSetting = JSON.parse(localStorage.getItem('resourceSetting'))\
            // 由于在最后一步，点击上一步的时候也会调用这个函数，因此加上一个限制，
            // 只有编辑的时候切是第一个进入的时候会调用函数，在改函数中设置本地idFirst 在编辑成功的时候删除
            if (this.editData && !localStorage.getItem('isFirst')) {
                console.log("1")
                this.formatData();
            } else if (this.operationStepTwoData.timeSetting) {
                console.log("2")
                this.timeSetting = this.operationStepTwoData.timeSetting;
                this.areaSetting = this.operationStepTwoData.areaSetting;
                this.goodsList = this.operationStepTwoData.goodsList;
                this.keyword = this.operationStepTwoData.keyword;
                this.timeArr = this.operationStepTwoData.timeArr;
                this.areaArr = this.operationStepTwoData.areaArr;
                this.peopleSetting = this.operationStepTwoData.peopleSetting;
                this.resourceSetting = this.operationStepTwoData.resourceSetting;
            } else {
                console.log("3")
                this.getArea()
            }
        },
        computed: {
            ...mapState({
                operationStepTwoData: state => state.operationStepTwoData,
            })
        },
        methods: {
            ...mapMutations(['setOperationStepTwoData']),
            // 筛选商品 去掉已经选择的
            selectGoodsChange(val) {
                this.goodsChoosableList = []
                this.goodsList.forEach((item, index) => {
                    if (item.good_id) {
                        this.goodsChoosableList[index] = item.good_id
                    }
                })
            },
            isSelectGoods(id) {
                if (this.goodsChoosableList.includes(id)) {
                    return false
                } else {
                    return true
                }
            },
            getKeyWords() {
                operationPromote_getKeyWorkList().then(res => {
                    this.keywordList = res.data
                }).catch(err => {
                    console.error(err)
                })
            },
            getGoodsList() {
                adminGoodListGood({category_id: this.category_id}).then(res => {
                    this.goodsInfoList = res.data
                }).catch(err => {
                    console.error(err)
                })
            },
            getArea() {
                let param = {};
                if ([3,4].includes(this.module)) {
                    param.type = 1;
                }
                operationPromote_getPosition(param).then(res => {
                    this.areaSetting = res.data.map(item => {
                        let obj = {
                            client_num: void 0,
                            click_rate: void 0,
                            transform_rate: void 0,
                            client_num_start: void 0,
                            client_num_end: void 0,
                            click_rate_start: void 0,
                            click_rate_end: void 0,
                            transform_rate_start: void 0,
                            transform_rate_end: void 0,
                        }
                        obj.title = item.name
                        obj.code = item.code
                        return obj
                    })
                }).catch(err => {
                    console.error(err)
                })
            },
            addGoods() {
                let obj = {}
                this.goodsList.push(obj)
            },
            delGoods(index) {
                this.goodsList.splice(index, 1)
                this.goodsChoosableList.splice(index, 1)
            },
            async confirmForm() {
                let isErrNum = 0;
                let isStartBig = 0;
                this.timeSetting.forEach((item, index) => {
                    if (item.client_num == undefined) {
                        this.$refs[`time_client_num_${index}`][0].$el.classList.add('isError');
                        isErrNum = 1;
                    } else {
                        this.$refs[`time_client_num_${index}`][0].$el.classList.remove('isError');
                    }
                    if (item.click_rate == undefined) {
                        this.$refs[`time_click_rate_${index}`][0].$el.classList.add('isError');
                        isErrNum = 1;
                    } else {
                        this.$refs[`time_click_rate_${index}`][0].$el.classList.remove('isError');
                    }
                    if (item.transform_rate == undefined) {
                        this.$refs[`time_transform_rate_${index}`][0].$el.classList.add('isError');
                        isErrNum = 1;
                    } else {
                        this.$refs[`time_transform_rate_${index}`][0].$el.classList.remove('isError');
                    }
                    if (item.client_num_start == undefined) {
                        this.$refs[`time_client_num_start_${index}`][0].$el.classList.add('isError');
                        isErrNum = 1;
                    } else {
                        this.$refs[`time_client_num_start_${index}`][0].$el.classList.remove('isError');
                    }
                    if (item.client_num_end == undefined) {
                        this.$refs[`time_client_num_end_${index}`][0].$el.classList.add('isError');
                        isErrNum = 1;
                    } else {
                        this.$refs[`time_client_num_end_${index}`][0].$el.classList.remove('isError');
                    }
                    if (!this.checkStatus(item.client_num_start, item.client_num_end)) {
                        this.$refs[`time_client_num_start_${index}`][0].$el.classList.add('isError');
                        isStartBig = 1;
                    } else {
                        this.$refs[`time_client_num_start_${index}`][0].$el.classList.remove('isError');
                    }
                    if (item.click_rate_start == undefined) {
                        this.$refs[`time_click_rate_start_${index}`][0].$el.classList.add('isError');
                        isErrNum = 1;
                    } else {
                        this.$refs[`time_click_rate_start_${index}`][0].$el.classList.remove('isError');
                    }
                    if (item.click_rate_end == undefined) {
                        this.$refs[`time_click_rate_end_${index}`][0].$el.classList.add('isError');
                        isErrNum = 1;
                    } else {
                        this.$refs[`time_click_rate_end_${index}`][0].$el.classList.remove('isError');
                    }
                    if (!this.checkStatus(item.click_rate_start, item.click_rate_end)) {
                        this.$refs[`time_click_rate_start_${index}`][0].$el.classList.add('isError');
                        isStartBig = 1;
                    } else {
                        this.$refs[`time_click_rate_start_${index}`][0].$el.classList.remove('isError');
                    }
                    if (item.transform_rate_start == undefined) {
                        this.$refs[`time_transform_rate_start_${index}`][0].$el.classList.add('isError');
                        isErrNum = 1;
                    } else {
                        this.$refs[`time_transform_rate_start_${index}`][0].$el.classList.remove('isError');
                    }
                    if (item.transform_rate_end == undefined) {
                        this.$refs[`time_transform_rate_end_${index}`][0].$el.classList.add('isError');
                        isErrNum = 1;
                    } else {
                        this.$refs[`time_transform_rate_end_${index}`][0].$el.classList.remove('isError');
                    }
                    if (!this.checkStatus(item.transform_rate_start, item.transform_rate_end)) {
                        this.$refs[`time_transform_rate_start_${index}`][0].$el.classList.add('isError');
                        isStartBig = 1;
                    } else {
                        this.$refs[`time_transform_rate_start_${index}`][0].$el.classList.remove('isError');
                    }
                })
                this.areaSetting.forEach((item, index) => {
                    if (item.client_num == undefined) {
                        this.$refs[`area_client_num_${index}`][0].$el.classList.add('isError');
                        isErrNum = 1;
                    } else {
                        this.$refs[`area_client_num_${index}`][0].$el.classList.remove('isError');
                    }
                    if (item.click_rate == undefined) {
                        this.$refs[`area_click_rate_${index}`][0].$el.classList.add('isError');
                        isErrNum = 1;
                    } else {
                        this.$refs[`area_click_rate_${index}`][0].$el.classList.remove('isError');
                    }
                    if (item.transform_rate == undefined) {
                        this.$refs[`area_transform_rate_${index}`][0].$el.classList.add('isError');
                        isErrNum = 1;
                    } else {
                        this.$refs[`area_transform_rate_${index}`][0].$el.classList.remove('isError');
                    }
                    if (item.client_num_start == undefined) {
                        this.$refs[`area_client_num_start_${index}`][0].$el.classList.add('isError');
                        isErrNum = 1;
                    } else {
                        this.$refs[`area_client_num_start_${index}`][0].$el.classList.remove('isError');
                    }
                    if (item.client_num_end == undefined) {
                        this.$refs[`area_client_num_end_${index}`][0].$el.classList.add('isError');
                        isErrNum = 1;
                    } else {
                        this.$refs[`area_client_num_end_${index}`][0].$el.classList.remove('isError');
                    }
                    if (!this.checkStatus(item.client_num_start, item.client_num_end)) {
                        this.$refs[`area_client_num_start_${index}`][0].$el.classList.add('isError');
                        isStartBig = 1;
                    } else {
                        this.$refs[`area_client_num_start_${index}`][0].$el.classList.remove('isError');
                    }
                    if (item.click_rate_start == undefined) {
                        this.$refs[`area_click_rate_start_${index}`][0].$el.classList.add('isError');
                        isErrNum = 1;
                    } else {
                        this.$refs[`area_click_rate_start_${index}`][0].$el.classList.remove('isError');
                    }
                    if (item.click_rate_end == undefined) {
                        this.$refs[`area_click_rate_end_${index}`][0].$el.classList.add('isError');
                        isErrNum = 1;
                    } else {
                        this.$refs[`area_click_rate_end_${index}`][0].$el.classList.remove('isError');
                    }
                    if (!this.checkStatus(item.click_rate_start, item.click_rate_end)) {
                        this.$refs[`area_click_rate_start_${index}`][0].$el.classList.add('isError');
                        isStartBig = 1;
                    } else {
                        this.$refs[`area_click_rate_start_${index}`][0].$el.classList.remove('isError');
                    }
                    if (item.transform_rate_start == undefined) {
                        this.$refs[`area_transform_rate_start_${index}`][0].$el.classList.add('isError');
                        isErrNum = 1;
                    } else {
                        this.$refs[`area_transform_rate_start_${index}`][0].$el.classList.remove('isError');
                    }
                    if (item.transform_rate_end == undefined) {
                        this.$refs[`area_transform_rate_end_${index}`][0].$el.classList.add('isError');
                        isErrNum = 1;
                    } else {
                        this.$refs[`area_transform_rate_end_${index}`][0].$el.classList.remove('isError');
                    }
                    if (!this.checkStatus(item.transform_rate_start, item.transform_rate_end)) {
                        this.$refs[`area_transform_rate_start_${index}`][0].$el.classList.add('isError');
                        isStartBig = 1;
                    } else {
                        this.$refs[`area_transform_rate_start_${index}`][0].$el.classList.remove('isError');
                    }
                })
                if (isErrNum == 1) {
                    await this.$message.warning('每项为必填')
                    return false
                } else if (isStartBig == 1) {
                    await this.$message.warning('开始数不得大于结束数')
                    return false
                } else {
                    let time = {}, location = {};
                    let arr1 = [], arr2 = [];
                    arr1 = this.timeSetting.map(item => {
                        let obj = {
                            customers: {
                                potential_customers: item.client_num,
                                range_low: item.client_num_start,
                                range_high: item.client_num_end
                            },
                            click: {
                                click_rate: item.click_rate,
                                range_low: item.click_rate_start,
                                range_high: item.click_rate_end
                            },
                            conversion: {
                                conversion_rate: item.transform_rate,
                                range_low: item.transform_rate_start,
                                range_high: item.transform_rate_end
                            }
                        };
                        return obj
                    })
                    arr2 = this.areaSetting.map(item => {
                        let obj = {
                            code: item.code,
                            customers: {
                                potential_customers: item.client_num,
                                range_low: item.client_num_start,
                                range_high: item.client_num_end
                            },
                            click: {
                                click_rate: item.click_rate,
                                range_low: item.click_rate_start,
                                range_high: item.click_rate_end
                            },
                            conversion: {
                                conversion_rate: item.transform_rate,
                                range_low: item.transform_rate_start,
                                range_high: item.transform_rate_end
                            }
                        };
                        return obj
                    })
                    for (let key in arr1) {
                        time[key] = arr1[key];
                    }
                    for (let key in arr2) {
                        location[key] = arr2[key]
                    }
                    let params = {
                        time: time,
                        location: location,
                    }
                    operationPromote_gData(params).then(res => {
                        this.timeArr = res.data.time_setting.map((item, index) => {
                            let obj = {
                                client_num: item.customers,
                                click_rate: item.click.toFixed(2),
                                transform_rate: item.conversion.toFixed(2),
                                title: index + '~' + (index + 1)
                            }
                            return obj
                        })
                        this.areaArr = res.data.location.map(item => {
                            let obj = {
                                client_num: item.customers,
                                click_rate: item.click.toFixed(2),
                                transform_rate: item.conversion.toFixed(2),
                                code: item.code,
                                title: item.name
                            }
                            return obj
                        })
                        this.$emit('changeSkip', true)
                    }).catch(err => {
                        console.error(err)
                    })
                }
            },
            submitForm() {
                let isErrNum = 0;
                let sum1 = 0, sum2 = 0;
                this.timeArr.forEach((item, index) => {
                    if (item.client_num == undefined) {
                        this.$refs[`time_skip_client_num_${index}`][0].$el.classList.add('isError');
                        isErrNum = 1;
                    } else {
                        this.$refs[`time_skip_client_num_${index}`][0].$el.classList.remove('isError');
                    }
                    if (item.click_rate == undefined) {
                        this.$refs[`time_skip_click_rate_${index}`][0].$el.classList.add('isError');
                        isErrNum = 1;
                    } else {
                        this.$refs[`time_skip_click_rate_${index}`][0].$el.classList.remove('isError');
                    }
                    if (item.transform_rate == undefined) {
                        this.$refs[`time_skip_transform_rate_${index}`][0].$el.classList.add('isError');
                        isErrNum = 1;
                    } else {
                        this.$refs[`time_skip_transform_rate_${index}`][0].$el.classList.remove('isError');
                    }
                })
                this.areaArr.forEach((item, index) => {
                    if (item.client_num == undefined) {
                        this.$refs[`area_skip_client_num_${index}`][0].$el.classList.add('isError');
                        isErrNum = 1;
                    } else {
                        this.$refs[`area_skip_client_num_${index}`][0].$el.classList.remove('isError');
                    }
                    if (item.click_rate == undefined) {
                        this.$refs[`area_skip_click_rate_${index}`][0].$el.classList.add('isError');
                        isErrNum = 1;
                    } else {
                        this.$refs[`area_skip_click_rate_${index}`][0].$el.classList.remove('isError');
                    }
                    if (item.transform_rate == undefined) {
                        this.$refs[`area_skip_transform_rate_${index}`][0].$el.classList.add('isError');
                        isErrNum = 1;
                    } else {
                        this.$refs[`area_skip_transform_rate_${index}`][0].$el.classList.remove('isError');
                    }
                })
                this.peopleSetting.forEach((item, index) => {
                    if (item.client_num == undefined) {
                        this.$refs[`people_client_num_${index}`][0].$el.classList.add('isError');
                        isErrNum = 1;
                    } else {
                        this.$refs[`people_client_num_${index}`][0].$el.classList.remove('isError');
                    }
                    if (item.click_rate == undefined) {
                        this.$refs[`people_click_rate_${index}`][0].$el.classList.add('isError');
                        isErrNum = 1;
                    } else {
                        this.$refs[`people_click_rate_${index}`][0].$el.classList.remove('isError');
                    }
                    if (item.transform_rate == undefined) {
                        this.$refs[`people_transform_rate_${index}`][0].$el.classList.add('isError');
                        isErrNum = 1;
                    } else {
                        this.$refs[`people_transform_rate_${index}`][0].$el.classList.remove('isError');
                    }
                    sum1 += item.client_num;
                })
                if ([2,4].includes(this.module)) {
                    this.resourceSetting.forEach((item, index) => {
                        if (item.client_num == undefined) {
                            this.$refs[`resource_client_num_${index}`][0].$el.classList.add('isError');
                            isErrNum = 1;
                        } else {
                            this.$refs[`resource_client_num_${index}`][0].$el.classList.remove('isError');
                        }
                        if (item.click_rate == undefined) {
                            this.$refs[`resource_click_rate_${index}`][0].$el.classList.add('isError');
                            isErrNum = 1;
                        } else {
                            this.$refs[`resource_click_rate_${index}`][0].$el.classList.remove('isError');
                        }
                        if (item.transform_rate == undefined) {
                            this.$refs[`resource_transform_rate_${index}`][0].$el.classList.add('isError');
                            isErrNum = 1;
                        } else {
                            this.$refs[`resource_transform_rate_${index}`][0].$el.classList.remove('isError');
                        }
                        sum2 += item.client_num
                    })
                }
                if ([1,3].includes(this.module)) {
                    if (this.goodsList.length == 0) {
                        isErrNum = 1;
                    }
                    if (this.keyword == '') {
                        isErrNum = 1;
                        this.$refs.keyword.$el.classList.add('isError');
                    } else {
                        this.$refs.keyword.$el.classList.remove('isError');
                    }
                    this.goodsList.forEach((item, index) => {
                        if (item.good_id == undefined) {
                            this.$refs[`goods_select_${index}`].$el.classList.add('isError');
                            isErrNum = 1;
                        } else {
                            this.$refs[`goods_select_${index}`].$el.classList.remove('isError');
                        }
                        if (item.price == undefined) {
                            this.$refs[`goods_price_${index}`].$el.classList.add('isError');
                            isErrNum = 1;
                        } else {
                            this.$refs[`goods_price_${index}`].$el.classList.remove('isError');
                        }
                        if (item.sales == undefined) {
                            this.$refs[`goods_sales_${index}`].$el.classList.add('isError');
                            isErrNum = 1;
                        } else {
                            this.$refs[`goods_sales_${index}`].$el.classList.remove('isError');
                        }
                        if (item.conversion == undefined) {
                            this.$refs[`goods_conversion_${index}`].$el.classList.add('isError');
                            isErrNum = 1;
                        } else {
                            this.$refs[`goods_conversion_${index}`].$el.classList.remove('isError');
                        }
                        if (item.click_rate == undefined) {
                            this.$refs[`goods_click_${index}`].$el.classList.add('isError');
                            isErrNum = 1;
                        } else {
                            this.$refs[`goods_click_${index}`].$el.classList.remove('isError');
                        }
                        if (item.quality_score == undefined) {
                            this.$refs[`goods_quality_score_${index}`].$el.classList.add('isError');
                            isErrNum = 1;
                        } else {
                            this.$refs[`goods_quality_score_${index}`].$el.classList.remove('isError');
                        }
                        if (item.wave_range_start == undefined) {
                            this.$refs[`goods_range_start_${index}`].$el.classList.add('isError');
                            isErrNum = 1;
                        } else {
                            this.$refs[`goods_range_start_${index}`].$el.classList.remove('isError');
                        }
                        if (item.wave_range_end == undefined) {
                            this.$refs[`goods_range_end_${index}`].$el.classList.add('isError');
                            isErrNum = 1;
                        } else {
                            this.$refs[`goods_range_end_${index}`].$el.classList.remove('isError');
                        }
                    })
                }
                if (sum1 != 100) {
                    isErrNum = 1;
                }
                if ([2,4].includes(this.module) && sum2 != 100) {
                    isErrNum = 1;
                }
                if (isErrNum == 1) {
                    if (sum1 != 100) {
                        isErrNum = 1;
                        this.$message.warning('人群定向客户比例相加要等于100！')
                    } else if ([2,4].includes(this.module) && sum2 != 100) {
                        isErrNum = 1;
                        this.$message.warning('资源位客户比例相加要等于100！')
                    } else if (this.goodsList.length == 0 && [1,3].includes(this.module)) {
                        this.$message.warning('请添加商品！')
                    } else {
                        this.$message.warning('每项为必填')
                    }
                    return false
                } else {
                    let arr1 = [], arr2 = [], arr3 = [], arr4 = [], arr5 = [], arr6 = [], arr7 = [];
                    arr1 = this.timeArr.map(item => {
                        let obj = {
                            customers: item.client_num,
                            click: item.click_rate,
                            conversion: item.transform_rate
                        }
                        return obj
                    })
                    arr2 = this.areaArr.map(item => {
                        let obj = {
                            customers: item.client_num,
                            click: item.click_rate,
                            conversion: item.transform_rate,
                            code: item.code,
                            name: item.title
                        }
                        return obj
                    })
                    arr3 = this.peopleSetting.map(item => {
                        let obj = {
                            customers: item.client_num,
                            click: item.click_rate,
                            conversion: item.transform_rate
                        }
                        return obj
                    })
                    arr4 = this.resourceSetting.map(item => {
                        let obj = {
                            customers: item.client_num,
                            click: item.click_rate,
                            conversion: item.transform_rate
                        }
                        return obj
                    })
                    arr5 = this.timeSetting.map((item, index) => {
                        let obj = {
                            type: 1,
                            period_time: index + 1,
                            area_code: '',
                            data: {
                                customers: item.client_num,
                                click: item.click_rate,
                                conversion: item.transform_rate,
                                customers_low: item.client_num_start,
                                customers_high: item.client_num_end,
                                click_low: item.click_rate_start,
                                click_high: item.click_rate_end,
                                conversion_low: item.transform_rate_start,
                                conversion_high: item.transform_rate_end,
                            }
                        }
                        return obj
                    })
                    arr6 = this.areaSetting.map((item, index) => {
                        let obj = {
                            type: 2,
                            period_time: '',
                            area_code: item.code,
                            data: {
                                customers: item.client_num,
                                click: item.click_rate,
                                conversion: item.transform_rate,
                                customers_low: item.client_num_start,
                                customers_high: item.client_num_end,
                                click_low: item.click_rate_start,
                                click_high: item.click_rate_end,
                                conversion_low: item.transform_rate_start,
                                conversion_high: item.transform_rate_end,
                            }
                        }
                        return obj
                    })
                    arr6.push.apply(arr6, arr5);
                    if ([1,3].includes(this.module)) {
                        arr7 = this.goodsList.map((item, index) => {
                            let obj = {
                                goods_id: item.good_id,
                                sale_num: item.sales,
                                click_rate: item.click_rate,
                                conversion_rate: item.conversion,
                                quality_score: item.quality_score,
                                range_low: item.wave_range_start,
                                range_high: item.wave_range_end,
                                sale_price: item.price,
                            }
                            return obj
                        })
                    }
                    let time = {}, location = {}, people = {}, resource = {}, period = {}, goods = {};
                    for (let key in arr1) {
                        time[key] = arr1[key];
                    }
                    for (let key in arr2) {
                        location[key] = arr2[key]
                    }
                    for (let key in arr3) {
                        people[key] = arr3[key];
                    }
                    for (let key in arr4) {
                        resource[key] = arr4[key]
                    }
                    for (let key in arr6) {
                        period[key] = arr6[key]
                    }
                    for (let key in arr7) {
                        goods[key] = arr7[key]
                    }
                    let stepTwoData = {
                        time: time,
                        location: location,
                        people: people,
                        resources: resource,
                        period: period,
                        goods: goods,
                        keywork_id: this.keyword
                    };
                    let storeTwoData = {
                        timeSetting: this.timeSetting,
                        areaSetting: this.areaSetting,
                        goodsList: this.goodsList,
                        keyword: this.keyword,
                        timeArr: this.timeArr,
                        areaArr: this.areaArr,
                        peopleSetting: this.peopleSetting,
                        resourceSetting: this.resourceSetting,
                    }
                    this.setOperationStepTwoData(storeTwoData)
                    this.$emit('stepActiveAdd', stepTwoData)
                }
            },
            resetErr() {
                this.peopleSetting.forEach((item, index) => {
                    this.$refs[`people_client_num_${index}`][0].$el.classList.remove('isError');
                    this.$refs[`people_click_rate_${index}`][0].$el.classList.remove('isError');
                    this.$refs[`people_transform_rate_${index}`][0].$el.classList.remove('isError');
                })
                if ([2,4].includes(this.module)) {
                    this.resourceSetting.forEach((item, index) => {
                        this.$refs[`resource_client_num_${index}`][0].$el.classList.remove('isError');
                        this.$refs[`resource_click_rate_${index}`][0].$el.classList.remove('isError');
                        this.$refs[`resource_transform_rate_${index}`][0].$el.classList.remove('isError');
                    })
                }
            },
            formatData() {
                this.timeSetting = [];
                this.areaSetting = [];
                this.timeArr = [];
                this.areaArr = [];
                this.peopleSetting = [];
                this.resourceSetting = [];
                this.editData.period.forEach((item, index) => {
                    if (item.type == 1) {
                        let obj = {
                            client_num: item.data.customers,
                            client_num_start: item.data.customers_low,
                            client_num_end: item.data.customers_high,
                            click_rate: item.data.click,
                            click_rate_start: item.data.click_low,
                            click_rate_end: item.data.click_high,
                            transform_rate: item.data.conversion,
                            transform_rate_start: item.data.conversion_low,
                            transform_rate_end: item.data.conversion_high,
                        }
                        if (item.period_time == 1) {
                            obj.title = '0~6'
                        } else if (item.period_time == 2) {
                            obj.title = '6~12'
                        } else if (item.period_time == 3) {
                            obj.title = '12~18'
                        } else {
                            obj.title = '18~24'
                        }
                        this.timeSetting.push(obj)
                    } else {
                        if((['搜索引擎推广','信息流推广'].includes(this.editData.module_id) && [1, 2].includes(this.module))
                            || (["国际搜索引擎推广", "海外社交媒体推广"].includes(this.editData.module_id) && [3, 4].includes(this.module)))
                        {
                            let obj = {
                                code: item.area_code,
                                client_num: item.data.customers,
                                client_num_start: item.data.customers_low,
                                client_num_end: item.data.customers_high,
                                click_rate: item.data.click,
                                click_rate_start: item.data.click_low,
                                click_rate_end: item.data.click_high,
                                transform_rate: item.data.conversion,
                                transform_rate_start: item.data.conversion_low,
                                transform_rate_end: item.data.conversion_high,
                            }
                            const positionListTmp = [3,4].includes(this.module) ? this.globalList : this.positionList;
                            positionListTmp.forEach(item1 => {
                                if (item1.code == item.area_code) {
                                    obj.title = item1.name
                                }
                            })
                            this.areaSetting.push(obj)
                        } else {
                            //国内，国外之间的切换的地区要重新调整
                            this.getArea()
                        }

                    }
                })
                this.editData.time.forEach((item, index) => {
                    let obj = {
                        client_num: item.potential_customers,
                        click_rate: item.click_rate.toFixed(2),
                        transform_rate: item.conversion_rate.toFixed(2),
                        title: item.period_time + '~' + (item.period_time + 1)
                    }
                    this.timeArr.push(obj)
                })
                this.editData.location.forEach((item, index) => {
                    let obj = {
                        code: item.code,
                        client_num: item.potential_customers,
                        click_rate: item.click_rate.toFixed(2),
                        transform_rate: item.conversion_rate.toFixed(2),
                        title: item.provinces
                    }
                    this.areaArr.push(obj)
                })
                this.editData.setting.forEach((item, index) => {
                    if (item.status == 1) {
                        let obj = {
                            client_num: item.potential_customers,
                            click_rate: item.click_rate,
                            transform_rate: item.conversion_rate,
                        }
                        this.peopleList.forEach(item2 => {
                            if (item2.type == item.type) {
                                obj.title = item2.name
                            }
                        })
                        this.peopleSetting.push(obj)
                    } else {
                        let obj = {
                            client_num: item.potential_customers,
                            click_rate: item.click_rate,
                            transform_rate: item.conversion_rate,
                        }
                        this.resourceList.forEach(item2 => {
                            if (item2.type == item.type) {
                                obj.title = item2.name
                            }
                        })
                        this.resourceSetting.push(obj)
                    }
                })
                this.keyword = this.editData.keywork_id;
                this.editData.goods.forEach(item => {
                    let obj = {
                        good_id: item.goods_id,
                        sales: item.sale_num,
                        click_rate: item.click_rate,
                        conversion: item.conversion_rate,
                        quality_score: item.quality_score,
                        wave_range_start: item.range_low,
                        wave_range_end: item.range_high,
                        price: item.sale_price,
                    }
                    this.goodsList.push(obj)
                })
                this.goodsList.forEach((item, index) => {
                    if (item.good_id) {
                        this.goodsChoosableList[index] = item.good_id
                    }
                })
                localStorage.setItem("isFirst",true)
            },
            checkStatus(start, end) {
                let res = false;
                if (start < end) {
                    res = true
                }
                return res
            },
            changeInput(e){
                // console.log(e.target.value.indexOf('.'));
                // if(e.target.value.indexOf('.')>=0){
                //     e.target.value=e.target.value.substring(0,e.target.value.indexOf('.'+ 3))
                // }
                let value = e.target.value;
                // 如果输入值中包含小数点
                if (value.includes('.')) {
                    let parts = value.split('.');
                // 保留小数点前和小数点后两位
                if (parts[1].length > 2) {
                    parts[1] = parts[1].slice(0, 2);
                    value = parts.join('.');
                    }
                }
                e.target.value = value;
            }
        }
    }
</script>

<style scoped lang="scss">
    .head-content {
        display: flex;
        justify-content: space-between;

        .head-left-title {
            margin-right: 10px;
            font-size: 16px;
            color: #333333;
        }
    }

    ::v-deep .el-input-number .el-input__inner {
        text-align: left;
    }

    .customTable {
        .el-input-number {
            width: 120px;
        }

        .wave-box {
            display: flex;
            justify-content: center;
            align-items: center;

            .el-input-number {
                width: 100px;
            }

            .divide-line {
                width: 14px;
                height: 2px;
                background: #BFBFBF;
                margin: 0 4px;
            }

            span {
                margin-left: 6px;
            }
        }
    }

    .setting-box {
        margin-top: 40px;
    }

    .content-title {
        position: relative;
        padding-left: 14px;
        margin-bottom: 22px;
        font-size: 16px;

        &:before {
            content: '';
            width: 4px;
            height: 16px;
            background-color: #2DC079;
            border-radius: 2px;
            position: absolute;
            left: 0;
            top: 3px;
        }
    }

    .item-content {
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        margin-bottom: 16px;

        .even-content {
            display: flex;
            align-items: center;
            width: 100%;
        }

        .odd-content {
            display: flex;
            width: 100%;
            align-items: center;
            margin-top: 10px;

            .odd-item {
                display: flex;
                align-items: center;
                margin-left: 40px;
                width: 200px;

                &:nth-of-type(2) {
                    margin-left: 0;
                }

                .el-input-number {
                    margin-left: 0;
                }
            }
        }

        .item-title {
            width: 120px;
            text-align: right;
            margin-right: 10px;
            font-size: 16px;
            color: #333333;
        }

        .divide {
            width: 15px;
            height: 2px;
            background: #D2D2D2;
            margin: 0 4px;
        }

        .el-input-number {
            margin-left: 40px;

            &:nth-of-type(2) {
                margin-left: 0;
            }
        }

        .item-content-title {
            width: 200px;
            margin-left: 40px;
            text-align: center;
            font-size: 16px;
            color: #333333;

            &:nth-of-type(2) {
                margin-left: 0;
            }
        }
    }

    .item-input {
        &.max {
            width: 200px;
        }

        &.medium {
            width: 182px;
        }

        &.mini {
            width: 70px;
        }
    }

    .input-text {
        margin: 0 2px;
    }

    .vertical-content {
        display: flex;
        flex-wrap: wrap;

        .vertical-item {
            margin-left: 40px;
            margin-top: 20px;
            display: flex;

            &:nth-of-type(6n + 1) {
                margin-left: 0;
            }

            .left-box {
                width: 120px;
                margin-right: 10px;


                .inner-item {
                    height: 40px;
                    display: flex;
                    align-items: center;
                    justify-content: flex-end;
                    font-size: 16px;
                    color: #333333;

                    &:first-child {
                        margin-top: 26px;
                    }
                }
            }


            .right-box {
                width: 200px;
            }

            .inner-item {
                margin-top: 20px;
            }
        }

        .item-title {
            width: 200px;
            text-align: center;
            font-size: 16px;
            color: #333333;
            margin-bottom: 10px;
        }

    }
</style>
