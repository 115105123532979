<template>
    <div class="form-content">
        <el-form ref="form" :model="form" :rules="rules" label-width="126px"
                 style="margin-left: 0px;margin-top: 60px">
            <el-form-item label="竞争店铺总数" prop="shop">
                <el-input-number :min="0"
                                 :max="99999"
                                 :controls="false"
                                 :precision="0" v-model="form.shop" style="width: 200px;"></el-input-number>
            </el-form-item>
            <el-form-item label="竞争店铺日限额" prop="shop_num">
                <el-input-number :min="0"
                                 :max="99999"
                                 :controls="false"
                                 :precision="0" v-model="form.shop_num" style="width: 200px;"></el-input-number>
            </el-form-item>
            <el-form-item label="波动范围">
                <div class="form-item">
                    <el-form-item prop="shop_range_low">
                        <el-input-number class="item-input mini"
                                         v-model="form.shop_range_low"
                                         :min="-99"
                                         :max="100"
                                         :controls="false"
                                         :precision="0"></el-input-number>
                        <span class="input-text">%</span>
                    </el-form-item>
                    <div class="divide"></div>
                    <el-form-item prop="shop_range_height">
                        <el-input-number class="item-input mini"
                                         v-model="form.shop_range_high"
                                         ref="shop_range_high"
                                         :min="-99"
                                         :max="100"
                                         :controls="false"
                                         :precision="0"></el-input-number>
                        <span class="input-text">%</span>
                    </el-form-item>
                </div>
            </el-form-item>
        </el-form>
        <div class="setting-box">
            <div class="content-title">时段设置</div>
            <div class="vertical-content">
                <div class="vertical-item" v-for="(item,index) in timeArr" :key="index">
                    <div class="left-box" v-if="index % 6 === 0">
                        <div class="inner-item">竞争店铺出价</div>
                        <div class="inner-item">波动范围</div>
                    </div>
                    <div class="right-box">
                        <div class="item-title">{{item.title}}</div>
                        <el-input-number class="item-input max" :ref="`shop_price_${index}`" v-model="item.shop_price"
                                         :min="0"
                                         :max="99999"
                                         :controls="false"
                                         :precision="0"></el-input-number>
                        <div class="form-item">
                            <el-input-number class="item-input mini"
                                             :ref="`shop_price_start_${index}`"
                                             v-model="item.shop_price_start"
                                             :min="-99"
                                             :max="100"
                                             :controls="false"
                                             :precision="0"></el-input-number>
                            <span class="input-text">%</span>
                            <div class="divide"></div>
                            <el-input-number class="item-input mini"
                                             :ref="`shop_price_end_${index}`"
                                             v-model="item.shop_price_end"
                                             :min="-99"
                                             :max="100"
                                             :controls="false"
                                             :precision="0"></el-input-number>
                            <span class="input-text">%</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: "CompetitionShop",
        props: ['editData'],
        data() {
            return {
                form: {},
                rules: {
                    shop: [
                        {required: true, message: '请输入竞争店铺总数', trigger: 'blur'},
                    ],
                    shop_num: [
                        {required: true, message: '请输入竞争店铺日限额', trigger: 'blur'}
                    ],
                    shop_range_low: [
                        {required: true, message: '请输入波动范围', trigger: 'blur'}
                    ],
                    shop_range_high: [
                        {required: true, message: '请输入波动范围', trigger: 'blur'}
                    ],
                },
                timeArr: [],
            }
        },
        created() {
            for (let i = 0; i < 24; i += 6) {
                let obj = {
                    title: i + '~' + (i + 6),
                    shop_price: void 0,
                    shop_price_start: void 0,
                    shop_price_end: void 0,
                }
                this.timeArr.push(obj)
            }
            if (this.editData) {
                this.formatData()
            }
        },
        methods: {
            confirmForm() {
                this.$refs['form'].validate((valid) => {
                    if (valid) {
                        let isErrNum = 0;
                        this.timeArr.forEach((item, index) => {
                            if (item.shop_price == undefined) {
                                this.$refs[`shop_price_${index}`][0].$el.classList.add('isError');
                                isErrNum = 1;
                            } else {
                                this.$refs[`shop_price_${index}`][0].$el.classList.remove('isError');
                            }
                            if (item.shop_price_start == undefined) {
                                this.$refs[`shop_price_start_${index}`][0].$el.classList.add('isError');
                                isErrNum = 1;
                            } else {
                                this.$refs[`shop_price_start_${index}`][0].$el.classList.remove('isError');
                            }
                            if (item.shop_price_end == undefined) {
                                this.$refs[`shop_price_end_${index}`][0].$el.classList.add('isError');
                                isErrNum = 1;
                            } else {
                                this.$refs[`shop_price_end_${index}`][0].$el.classList.remove('isError');
                            }
                        })
                        if (!this.form.shop_range_high) {
                            this.$refs.shop_range_high.$el.classList.add('isError');
                            isErrNum = 1;
                        } else {
                            this.$refs.shop_range_high.$el.classList.remove('isError');
                        }
                        if (isErrNum == 1) {
                            this.$message.warning('每项为必填')
                            return false
                        }
                        let offer = {}, arr1 = [];
                        arr1 = this.timeArr.map(item => {
                            let obj = {
                                offer_fee: item.shop_price,
                                range_low: item.shop_price_start,
                                range_high: item.shop_price_end,
                            }
                            return obj
                        })
                        for (let key in arr1) {
                            offer[key] = arr1[key];
                        }
                        let stepThreeData = {
                            competition_shop: this.form.shop,
                            competition_shop_daily_limit: this.form.shop_num,
                            shop_range_low: this.form.shop_range_low,
                            shop_range_high: this.form.shop_range_high,
                            offer: offer
                        }
                        this.$emit('stepActiveAdd', stepThreeData)
                    } else {
                        return false;
                    }
                })
            },
            formatData() {
                this.timeArr = [];
                this.form.shop = this.editData.competition_shop;
                this.form.shop_num = this.editData.competition_shop_daily_limit
                this.form.shop_range_low = this.editData.shop_range_low
                this.form.shop_range_high = this.editData.shop_range_high
                let arr = this.editData.offer.map(item => {
                    let obj = {
                        shop_price: item.offer_fee,
                        shop_price_start: item.range_low,
                        shop_price_end: item.range_high,
                    }
                    if (item.type == 0) {
                        obj.title = '0~6';
                        obj.show = 1;
                    } else if (item.type == 1) {
                        obj.title = '6~12';
                        obj.show = 2;
                    } else if (item.type == 2) {
                        obj.title = '12~18';
                        obj.show = 3;
                    } else {
                        obj.title = '18~24';
                        obj.show = 3;
                    }
                    return obj
                })
                arr.sort(this.sortBy('show'))
                console.log(arr,9999)
                this.timeArr = arr
            },
            sortBy(props) {
                return function(a,b) {
                    return a[props] - b[props];
                }
            }
        }
    }
</script>

<style scoped lang="scss">
    ::v-deep .el-form-item.is-required:not(.is-no-asterisk) > .el-form-item__label:before {
        content: '';
    }

    ::v-deep .el-input-number .el-input__inner {
        text-align: left;
    }

    .form-item {
        display: flex;
        align-items: center;
        width: 200px;

        .input-text {
            margin: 0 2px;
        }

    }

    .divide {
        width: 15px;
        height: 2px;
        background: #D2D2D2;
        margin: 0 4px;
    }

    .item-input {
        &.max {
            width: 200px;
        }

        &.medium {
            width: 182px;
        }

        &.mini {
            width: 70px;
        }
    }

    .content-title {
        position: relative;
        padding-left: 14px;
        margin-bottom: 22px;
        font-size: 16px;

        &:before {
            content: '';
            width: 4px;
            height: 16px;
            background-color: #2DC079;
            border-radius: 2px;
            position: absolute;
            left: 0;
            top: 3px;
        }
    }

    .vertical-content {
        display: flex;
        flex-wrap: wrap;

        .vertical-item {
            margin-left: 40px;
            margin-top: 20px;
            display: flex;

            &:nth-of-type(6n + 1) {
                margin-left: 0;
            }

            .left-box {
                width: 120px;
                margin-right: 10px;


                .inner-item {
                    height: 40px;
                    display: flex;
                    align-items: center;
                    justify-content: flex-end;
                    font-size: 16px;
                    color: #333333;

                    &:first-child {
                        margin-top: 26px;
                    }


                    .divide {
                        width: 15px;
                        height: 2px;
                        background: #D2D2D2;
                        margin: 0 4px;
                    }

                    .input-text {
                        margin: 0 2px;
                    }
                }
            }


            .right-box {
                width: 200px;

                .form-item {
                    margin-top: 20px;
                }
            }

            .inner-item {
                margin-top: 20px;
            }
        }

        .item-title {
            width: 200px;
            text-align: center;
            font-size: 16px;
            color: #333333;
            margin-bottom: 10px;
        }

    }
</style>