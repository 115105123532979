import { render, staticRenderFns } from "./OperationBasicData.vue?vue&type=template&id=2ccfcfd1&scoped=true"
import script from "./OperationBasicData.vue?vue&type=script&lang=js"
export * from "./OperationBasicData.vue?vue&type=script&lang=js"
import style0 from "./OperationBasicData.vue?vue&type=style&index=0&id=2ccfcfd1&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2ccfcfd1",
  null
  
)

export default component.exports