<template>
    <div class="form-content">
        <el-form ref="form" :model="form" :rules="rules" label-width="126px"
                 style="margin-left: 0px;margin-top: 0px">
            <el-form-item label="创建名称" prop="title">
                <el-input v-model="form.title" style="width: 420px;" ref="cursorInput" placeholder="2-50个字"></el-input>
            </el-form-item>
            <el-form-item label="模板分类" prop="module_id">
                <el-select v-model="form.module_id" placeholder="请选择模块分类" style="width: 420px;">
                    <el-option
                            v-for="item in moduleList"
                            :key="item.id"
                            :label="item.name"
                            :value="item.id">
                    </el-option>
                </el-select>
            </el-form-item>
            <el-form-item label="推广费用" prop="promote_fee">
                <el-input-number :min="0"
                                 :max="9999999"
                                 :controls="false"
                                 :precision="0" v-model="form.promote_fee" style="width: 420px;"
                                 ref="cursorInput"></el-input-number>
            </el-form-item>
            <el-form-item label="行业分类" prop="category_id">
                <el-cascader
                        style="width: 420px;"
                        v-model="form.category_id"
                        :options="industryList"
                        :props="categoryProps"
                        @change="chooseIndustry"
                >
                </el-cascader>
            </el-form-item>
        </el-form>
    </div>
</template>

<script>
    import {adminGoodGetCategory, operationPromote_info} from '@/utils/apis'
    import {mapMutations, mapState} from "vuex";

    export default {
        name: "OperationBasicData",
        data() {
            return {
                editId: this.$route.query.id || '',
                editData: null,
                form: {
                    title: '',
                    module_id: '',
                    category_id: '',
                },
                rules: {
                    title: [
                        {required: true, message: '请输入名称', trigger: 'blur'},
                        {min: 2, max: 50, message: '长度在 2 到 50 个字符', trigger: 'blur'}
                    ],
                    module_id: [
                        {required: true, message: '请选择模板', trigger: 'change'}
                    ],
                    promote_fee: [
                        {required: true, message: '请选择推广费用', trigger: 'blur'}
                    ],
                    category_id: [
                        {required: true, message: '请选择行业分类', trigger: 'change'}
                    ],
                },
                moduleList: [
                    {
                        id: 1,
                        name: '搜索引擎推广'
                    },
                    {
                        id: 2,
                        name: '信息流推广'
                    },
                    {
                        id: 3,
                        name: "国际搜索引擎推广",
                    },
                    {
                        id: 4,
                        name: "海外社交媒体推广"
                    }
                ],
                industryList: [],
                categoryProps: {value: 'id', label: 'name', children: 'children'},
            }
        },
        created() {
            if (this.editId) {
                this.getEditData()
            }
            this.getKeFuGetIC();
            if (this.operationBasicData.title) {
                this.form = this.operationBasicData
            }
        },
        computed: {
            ...mapState({
                operationBasicData: state => state.operationBasicData
            })
        },
        methods: {
            ...mapMutations(['setOperationBasicData']),
            getKeFuGetIC() {
                adminGoodGetCategory().then((res) => {
                    this.industryList = res.data;
                }).catch((err) => {
                    console.log('err', err)
                })
            },
            getEditData() {
                let id = this.editId
                operationPromote_info(id).then((res) => {
                    this.editData = res.data;
                    this.form.title = this.editData.title;
                    console.log("++", this.editData.module_id)
                    if (this.editData.module_id === '搜索引擎推广') {
                        this.form.module_id = 1;
                    }
                    if (this.editData.module_id === '信息流推广') {
                        this.form.module_id = 2;
                    }
                    if (this.editData.module_id === '国际搜索引擎推广') {
                        this.form.module_id = 3;
                    }
                    if (this.editData.module_id === '海外社交媒体推广') {
                        this.form.module_id = 4;
                    }
                    // this.form.module_id = this.editData.module_id == '信息流推广' ? 2 : 1;
                    this.form.category_id = this.editData.category_id;
                    this.form.promote_fee = this.editData.promote_fee;
                    this.$emit('getEditData', this.editData)
                }).catch((err) => {
                    console.log('err', err)
                })
            },
            confirmForm() {
                this.$refs['form'].validate((valid) => {
                    if (valid) {
                        this.setOperationBasicData(this.form)
                        this.$emit('stepActiveAdd', this.form)
                    } else {
                        return false;
                    }
                })
            },
            chooseIndustry(val) {
                this.form.category_id = val[val.length - 1]
            }
        }
    }
</script>

<style scoped lang="scss">
    ::v-deep .el-form-item.is-required:not(.is-no-asterisk) > .el-form-item__label:before {
        content: '';
    }

    ::v-deep .el-input-number .el-input__inner {
        text-align: left;
    }

</style>
